import React, { useEffect, useState, useMemo, useCallback } from "react";
import { StyleSheet, Image } from "react-native";
import Loading from "@components/atoms/Loading";
import Button from "@components/atoms/Button";
import OAuthLogin from "@components/organisms/OAuthRedirect/OAuthLogin";
import OAuthLinking from "@components/organisms/OAuthRedirect/OAuthLinking";
import { sendError } from "@lib/util/logger";
import ExceptionError from "@lib/errors/exceptionError";
import days from "dayjs";
import { Text, View, Card } from "@components/atoms/Themed";
import {
  getAuthorizationInfo,
  Authorization,
  isLoginMedia,
} from "@lib/util/oauth";
import { navigateByUrl, goBack } from "@navigation/navigate";
import Spacer from "@components/atoms/Spacer";
import Fonts from "@constants/Fonts";
import Colors from "@constants/Colors";
import useMessage from "@hooks/useMessage";
import NavigationBar from "@components/molecules/NavigationBar";
import _ from "lodash";
import { emptyWork } from "@components/atoms/AssetImage";
import { SnsTextGeneration } from "@lib/util/snsTextGeneration";

type Props = {
  code?: string;
  state: string;
};

function ErrorContent() {
  return (
    <View style={styles.errorContent}>
      <Text style={styles.errorText}>
        SNS連携に失敗しました。操作をやり直してください。
      </Text>
      <Spacer height={24} />
      <Image source={emptyWork} style={styles.error} />
      <Spacer height={27} />
      <Button onPress={() => goBack()} title="戻る" />
    </View>
  );
}

function ScreenContent({
  code,
  authorization,
  setMessage,
}: {
  code: string;
  authorization: Authorization;
  setMessage: (data: string) => void;
}) {
  if (
    authorization.action === "Login" &&
    isLoginMedia(authorization.platform)
  ) {
    return (
      <OAuthLogin
        code={code}
        codeVerifier={authorization.codeVerifier}
        onError={() => {
          setMessage(
            `${SnsTextGeneration(
              authorization.platform
            )}ログインに失敗しました。`
          );
          if (authorization.callbackUrl != null) {
            navigateByUrl(authorization.callbackUrl);
          } else {
            goBack();
          }
        }}
        platform={authorization.platform}
      />
    );
  }
  if (authorization.action === "Linking") {
    return (
      <OAuthLinking
        code={code}
        codeVerifier={authorization.codeVerifier}
        onError={() => {
          setMessage(
            `${SnsTextGeneration(authorization.platform)}連携に失敗しました。`
          );
          if (authorization.callbackUrl !== undefined) {
            navigateByUrl(authorization.callbackUrl);
          } else {
            goBack();
          }
        }}
        onSuccess={() => {
          setMessage(
            `${SnsTextGeneration(authorization.platform)}と連携しました。`
          );
          if (authorization.callbackUrl !== undefined) {
            navigateByUrl(authorization.callbackUrl);
          } else {
            goBack();
          }
        }}
        platform={authorization.platform}
      />
    );
  }
  return <ErrorContent />;
}

export default function OAuthRedirect({ code, state }: Props) {
  const [authorization, setAuthorization] = useState<Authorization | null>(
    null
  );
  const [loaded, setLoaded] = useState<boolean>(false);
  const { set: setMessage } = useMessage();

  // Memo: redirect urlに#フラグメントが追加されることがあるため整理する
  const exchangeToken = useMemo<string | undefined>(() => {
    if (code !== undefined) {
      return _.head(code.split("#"));
    }
    return undefined;
  }, [code]);
  const csrfState = useMemo<string | undefined>(
    () => _.head(state.split("#")),
    [state]
  );

  const loadPage = useCallback(async () => {
    try {
      const info = await getAuthorizationInfo();
      if (info === null) {
        throw new Error("連携に失敗しました。操作をやり直してください。");
      }
      if (info.expired < days().unix()) {
        throw new Error("有効期限が切れました。操作をやり直してください。");
      }
      if (csrfState === undefined || info.csrfState !== csrfState) {
        throw new Error("不正な操作です。操作をやり直してください。");
      }
      const loginable = isLoginMedia(info.platform);
      if (!loginable && info.action === "Login") {
        throw new Error("不正な操作です。操作をやり直してください。");
      }
      setAuthorization(info);
    } catch (e: unknown) {
      sendError(new ExceptionError("OAuthRedirect loadPage error", e));
    } finally {
      setLoaded(true);
    }
  }, [csrfState]);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  return (
    <View style={styles.container}>
      <NavigationBar />
      <Card style={styles.main}>
        {loaded ? (
          authorization !== null && exchangeToken !== undefined ? (
            <View style={styles.content}>
              <ScreenContent
                authorization={authorization}
                code={exchangeToken}
                setMessage={(title) =>
                  setMessage({
                    title,
                    mode: "toast",
                  })
                }
              />
            </View>
          ) : (
            <ErrorContent />
          )
        ) : (
          <View style={styles.content}>
            <Loading />
            <Spacer height={16} />
            <Text style={styles.text}>連携中...</Text>
          </View>
        )}
      </Card>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  main: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  content: {
    maxHeight: 200,
    width: "100%",
    alignItems: "center",
  },
  loading: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  text: {
    ...Fonts.xlb100,
  },
  errorContent: {
    padding: 16,
  },
  error: {
    width: 120,
    height: 120,
  },
  errorText: {
    ...Fonts.xlb160,
    color: Colors.gray,
  },
});
